.appBar {
  justify-content: space-between;
  background: "transparent" !important;
  background-color: transparent !important;
  box-shadow: none !important;
  padding: 1rem;
  z-index: 10;
}
.mobileBar {
  justify-content: space-between;
  background: "transparent" !important;
  box-shadow: none !important;
  padding: 1rem;
  z-index: 10;
  background-color: transparent !important;
}

.dappMobile {
  width: 100% !important;
  align-self: flex-end;
}
.connectButton {
  background-color: #27c7ff !important;
  border-radius: 20px !important;
  color: white !important;
  filter: drop-shadow(4px 4px 0px #ff3cbe) !important;
}
.linksButton {
  color: white !important;
  border-radius: 20px !important;
  font-size: 18px !important;
}
.tradeButton {
  background-color: #27c7ff !important;
  filter: drop-shadow(4px 4px 0px #ff3cbe) !important;
  color: white !important;
  padding-left: 2rem !important;
  padding-right: 2rem !important;
  border-radius: 15px !important;
}
.tradeButton:hover {
  background-color: #1eace0 !important;
}
.logo {
  color: #27c7ff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 4px 4px 0px #ff3cbe;
  font-size: 3em !important;
  padding-right: 5px;
  text-align: center;
  -webkit-text-stroke: 1px black;
}

.icon {
  min-width: "28px";
  min-height: "28px";
  width: "28px";
  margin-right: 5px;
}
.dapp-topbar {
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem;

  a,
  button {
    white-space: nowrap !important;
    overflow: hidden !important;
    margin: 1em !important;
    min-width: fit-content !important;
    min-height: 40px !important;
  }
}
.tablet .dapp-topbar {
  justify-content: space-between;
}
.framingButton {
  background: transparent;
  justify-content: "flex-end";
  align-items: "flex-end";
}

.bootstrapButton {
  filter: drop-shadow(0px 0px 0.35em #ff3cbe) !important;
  border: 8px solid #27c7ff !important;
  border-radius: 30px !important;
  background: url("../../assets/static_wide.png");
  height: 2.7em;
  width: 15em;
  font-size: 40px !important;
}
.backgroundPega {
  background-image: url("../../assets/background2.gif");
  min-height: 3em;
  background-size: cover;
  flex-grow: 1;
}
.scaffold {
  flex-flow: column;
  flex-direction: column;
  background-size: cover;
  display: block;
  overflow: auto;
}

.pegapalKing {
  background-size: cover;
  width: 20rem;
  height: 20rem;
  filter: drop-shadow(4px 4px 35px #27c7ff);
  align-self: center;
}

.inversePal {
  color: #ff3cbe;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 5px 5px 0px #27c7ff;
  font-size: 5.8em !important;
  text-align: center;
  -webkit-text-stroke: 1px black;
}

.subtitleInverse {
  font-size: 2.5em !important;
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  line-height: 48px !important;
}
.baseScreen {
  min-height: 85vh;
  position: relative;
}

.stake-button {
  align-self: center;
  width: 100%;
  min-width: 163px !important;
  max-width: 542px !important;
  height: 43px !important;
  &.wrap-page {
    // margin-left: 2%;
    width: 40%;
  }
}
.subheading2 {
  color: #27c7ff;
  text-shadow: 4px 4px 0px #ff3cbe;
  font-size: 4rem !important;
  -webkit-text-stroke: 1px black;
}
.box2 {
  padding-left: 12% !important;
  padding-right: 12% !important;
  margin-top: 7% !important;
  position: relative;
}
.doubleBoxes {
  width: 100% !important;
  padding-left: 6.5% !important;
  padding-right: 6.5%;
  align-self: center;
}
.miniTitle1 {
  color: white;
  text-shadow: 2px 2px 0px #ff3cbe;
  font-size: 2.5rem !important;
  -webkit-text-stroke: 1px black;
}
.miniTitle2 {
  color: white;
  text-shadow: 2px 2px 0px #27c7ff;
  font-size: 2.5rem !important;
  -webkit-text-stroke: 1px black;
  text-align: left;
}
.innerBox {
  width: 80% !important;
}
.info {
  font-size: 23px;
  -webkit-text-stroke: 1px black;
}
.infoBoxParent {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column !important;
}
.infoBox {
  background: #3f2b38;
  border: 1px solid #ff3cbe;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 1.5rem;
  width: 24rem !important;
  height: 22rem !important;
  margin-top: 10px;
}

.infoSubBox {
  background: #314349;
  border: 1px solid #27c7ff;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 1.5rem;
  width: 24rem !important;
  height: 22rem !important;
  margin-top: 10px;
}
.infosub {
  padding-bottom: 1rem;
}

.pegaToken {
  filter: drop-shadow(0px 0px 10px #ffffff);
  height: 14rem;
  width: 14rem;
}
.subheading3 {
  color: #ff3cbe;
  text-shadow: 4px 4px 0px #27c7ff;
  font-size: 4rem !important;
  -webkit-text-stroke: 1px black;
}

.stagesText {
  color: #27c7ff;
  text-shadow: 6px 6px 0px #625757;
  font-size: 4rem !important;
}
.copyPlanets {
  height: 16.5rem;
  width: 16.5rem;
}

.asteroid {
  position: absolute;
  top: 5%;
  left: 8%;
}

.moon {
  position: absolute;
  top: 55%;
  left: 18%;
}

.earth {
  position: absolute;
  top: 55%;
  left: 68%;
}

.sun {
  position: absolute;
  top: 5%;
  left: 78%;
}

.gameplanImg {
  width: 80vw !important;
  margin-top: 3rem;
}

.bootstrapButton1 {
  border: 8px solid #27c7ff !important;
  border-radius: 30px !important;
  background: url("../../assets/static_wide.png");
  height: 2.5em !important;
  width: 15em;
  font-size: 40px !important;
  margin-bottom: 2rem !important;
}
.bootstrapButton2 {
  border: 8px solid #ff3cbe !important;
  border-radius: 30px !important;
  background: url("../../assets/static_wide.png");
  height: 2.5em;
  width: 15em;
  font-size: 40px !important;
  margin-bottom: 2rem !important;
}
.backgroundDesign {
  background-image: url("../../assets/background1.gif");
  background-size: cover;
  min-height: 100vh;
  position: relative;
}
.mainBox {
  align-self: center;
}
.scaffold {
  height: 100vh;
  width: 100vw;
  display: flex;
}
.pegapal {
  background-size: cover;
}
.pegapalOne {
  position: absolute;
  left: 10%;
  width: 174px;
  height: 174px;
  top: 8%;
  filter: drop-shadow(0px 4px 10px #27c7ff);
  transform: matrix(-0.98, 0.17, 0.17, 0.98, 0, 0);
}
.pegapalTwo {
  position: absolute;
  left: 80%;
  top: 60%;
  width: 174px;
  height: 174px;
  filter: drop-shadow(0px 4px 20px #27c7ff);
  transform: rotate(-15deg);
}
.heading {
  color: #27c7ff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 6px 6px 0px #ff3cbe;
  font-size: 8.5em !important;
  text-align: center;
  -webkit-text-stroke: 1px black;
}
.pegaverseButtons {
  flex-direction: column !important;
  display: flex;
}
.subTitle {
  color: #ff3cbe;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 3px 3px 0px #27c7ff;
  font-size: 3.3em !important;
  text-align: center;
  -webkit-text-stroke: 1px black;
}

.stake-button {
  align-self: center;
  width: 100%;
  min-width: 163px !important;
  max-width: 542px !important;
  height: 43px !important;
  &.wrap-page {
    // margin-left: 2%;
    width: 40%;
  }
}

.mobile .stake-tab-panel,
.mobile .stake-tab-panel > div.MuiBox-root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
}

.mobile .stake-tab-panel.wrap-page {
  align-items: center;
}

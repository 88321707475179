body {
  margin: 0;
}
@font-face {
  font-family: "Zorque";
  font-style: normal;
  font-weight: 400;
  src: local("Zorque"), url("./assets/fonts/zorque.woff") format("woff");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Zorque", monospace;
}
